import React from 'react';
import { WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react';
import { wagmiConfig, projectId, ethereumClient } from './web3modalConfig';
import DepositForm from './DepositForm';

const App: React.FC = () => (
  <WagmiConfig config={wagmiConfig}>
    <div>
      <DepositForm />
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
    </div>
  </WagmiConfig>
);

export default App;
