import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { configureChains, createConfig } from 'wagmi';
import { mainnet, bsc, arbitrum, optimism, base } from 'wagmi/chains';
export const projectId = '69cc6bb70ff2372c31e89e54aaa18a6d';
const chains = [mainnet, bsc, arbitrum, optimism, base];
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({
    projectId,
    chains,
  }),
  publicClient,
});
export const ethereumClient = new EthereumClient(wagmiConfig, chains);
